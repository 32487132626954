import { Exception } from 'handlebars/runtime';
import StatesAndCitiesBrasil from '@/core/utils/json/StatesAndCitiesBrasil.json';
import cep from 'cep-promise';

const addressMixin = {
  methods: {
    async getStates() {
      return StatesAndCitiesBrasil;
    },
    async getCitiesByStateID(state_id) {
      const cities = await Object.values(StatesAndCitiesBrasil).filter((state) => state.state_id == state_id);
      return cities[0].cities;
    },
    async getAddressByCep(zip_code) {
      return await cep(zip_code, { timeout: 5000, providers: ['brasilapi'] })
        .then(async (response) => {
          const states = await this.getStates();
          const state = await Object.values(states).filter((state) => state.state_uf == response.state);

          const cities = await this.getCitiesByStateID(state[0].state_id);
          const city = Object.values(cities).filter((city) => city.name == response.city);

          return {
            zip_code: zip_code,
            address: response.neighborhood,
            number: null,
            neighborhood: response.neighborhood,
            ecosystem_state_id: state[0].state_id,
            ecosystem_city_id: city[0].id
          };
        })
        .catch((err) => {
          throw new Exception(err);
        });
    }
  }
};

export default addressMixin;
